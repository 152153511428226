import React from "react";

import { Box } from "@material-ui/core";

import { Blocage, Default, DiagnosticItem, Replacement, Resolution, SparePartFitting, Symptom } from "types/diagnostic";

import SparePartDetail from "../../SparePartDetail";

export const ReplacementItem: React.FunctionComponent<{ replacement: Replacement; prefix: string }> = ({
  replacement,
  prefix,
}) => {
  return (
    <div>{`${prefix} ${replacement.model.brand} - ${replacement.model.supplier_reference} - ${replacement.model.designation}`}</div>
  );
};

const BasicItem: React.FunctionComponent<{ item: Symptom | Default | Resolution; prefix: string }> = ({
  item,
  prefix,
}) => (
  <div>
    {prefix} {item.content}
  </div>
);

const SparePrtFitting: React.FunctionComponent<{ spare_part_fitting: SparePartFitting }> = ({ spare_part_fitting }) => (
  <Box key={spare_part_fitting.spare_part_id} mb={1}>
    <SparePartDetail sparePartId={spare_part_fitting.spare_part_id} />
  </Box>
);

interface DiagnosticItemProps {
  items: DiagnosticItem[];
  prefix?: string;
}

export const DiagnosticItems = ({ items, prefix = "" }: DiagnosticItemProps) => {
  const renderBlocageItem = (item: Blocage) => <div>{`${prefix} ${item.reason} - ${item.details}`}</div>;

  return (
    <>
      {items.map((item: DiagnosticItem) => {
        switch (item.type) {
          case "symptom":
            return <BasicItem item={item} prefix={prefix} />;
          case "default":
            return <BasicItem item={item} prefix={prefix} />;
          case "resolution":
            if ("context" in item && item.context === "irreparable") {
              return <BasicItem item={item} prefix="Machine non réparable" />;
            } else {
              return <BasicItem item={item} prefix={prefix} />;
            }
          case "replacement":
            return <ReplacementItem replacement={item} prefix={prefix} />;
          case "blocage":
            return renderBlocageItem(item);
          case "spare_part_fitting":
            return <SparePrtFitting spare_part_fitting={item} />;
          default:
            return null;
        }
      })}
    </>
  );
};

export default DiagnosticItems;
