import React, { ReactNode, useState } from "react";

import { Box, Collapse, ListItem, ListItemText, List as MUIList } from "@material-ui/core";
import { styled } from "@material-ui/core";
import { ChevronRight, ExpandMore } from "@material-ui/icons";

interface ListProps {
  items: ReactNode[];
  titleBlock: ReactNode;
  defaultOpen?: boolean;
  subtitle?: ReactNode;
}

const StyledMUIList = styled(MUIList)<any>(({ theme }) => ({
  backgroundColor: "#fff",
  borderBottom: `2px solid ${theme.palette.secondary.grey700}`,
}));

const List: React.FC<ListProps> = ({ items, titleBlock, subtitle = null, defaultOpen = false }) => {
  const [open, setOpen] = useState(defaultOpen);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <StyledMUIList>
      <ListItem button onClick={handleToggle}>
        <Box width="100%" display="flex" justifyContent="space-between">
          <Box flexGrow={1}>{titleBlock}</Box>
          <Box>{open ? <ExpandMore /> : <ChevronRight />}</Box>
        </Box>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box mx={2}>{subtitle}</Box>
        {items.map((item, index) => (
          <ListItem key={index}>
            <ListItemText primary={item} />
          </ListItem>
        ))}
      </Collapse>
    </StyledMUIList>
  );
};

export default List;
