import React, { useState } from "react";

import { SimpleForm } from "react-admin";

import { withStyles } from "@material-ui/core/styles";

import { SKU_TYPE_NEW, SKU_TYPE_SECOND_HAND } from "constants/sku";
import { SUPPLIER_NAME_ASWO } from "constants/supplier";

import AddSparePartNoRef from "pages/customerFiles/components/CustomerFileBillingQuotes/components/elementCreation/AddSparePart/components/AddSparePartNoRef";
import AddSparePartRefFound from "pages/customerFiles/components/CustomerFileBillingQuotes/components/elementCreation/AddSparePart/components/AddSparePartRefFound";
import {
  getSparePartBasePriceFromSparePartCost,
  getSparePartPriceWithTax,
} from "pages/customerFiles/components/CustomerFileBillingQuotes/services/sparePartPriceCalculator.js";

import { getSkuSellingPrice, getSkus } from "utils/api";
import { getAswoSparePart } from "utils/api";
import { getPricePretax } from "utils/price";

import AddSparePartStyles from "../AddSparePartStyles";
import AddSparePartASWORefSelection from "./components/AddSparePartAswoRefSelection";

const AddSparePart = ({
  classes,
  defaultSupplier,
  newSparePartElementsCount,
  addSparePart,
  handleCloseSparePart,
  isStockInstallation,
  productId,
}) => {
  const [modalState, setModalState] = useState({
    isAswoModalOpen: true,
    isSparePartModalOpen: false,
  });

  const [sparePartData, setSparePartData] = useState({
    isNoRefPiece: false,
    supplier: defaultSupplier,
    reference: "",
    skus: [],
    sku: null,
    quantity: 1,
    designation: "",
    sellingPrice: null,
    buyingPriceInCents: null,
    isSparePartPriceEditable: false,
    forcedSparePartPriceWithTax: null,
  });

  const updateModalState = (newState) => setModalState((prevState) => ({ ...prevState, ...newState }));
  const updateSparePartData = (newData) => setSparePartData((prevData) => ({ ...prevData, ...newData }));

  const findSkus = async () => {
    const skus = await getSkus({
      supplier: sparePartData.supplier.id,
      reference: sparePartData.reference,
      type: [SKU_TYPE_NEW, SKU_TYPE_SECOND_HAND],
      similar: true,
    });

    await enrichSkusWithAswoPayload(skus);

    const isNoRefPiece = skus.length <= 0;
    const selectedSku = isNoRefPiece ? null : selectDefaultSku(skus);

    updateSparePartData({
      skus,
      isNoRefPiece,
      sku: selectedSku,
    });

    updateModalState({
      isAswoModalOpen: false,
      isSparePartModalOpen: true,
    });

    if (selectedSku) {
      handleChangeSku(selectedSku);
    }
  };

  const enrichSkusWithAswoPayload = async (skus) => {
    await Promise.all(
      skus.map(async (sku) => {
        const aswoSkuModelReference = sku.references.find((ref) => ref.supplier_name === SUPPLIER_NAME_ASWO);
        if (aswoSkuModelReference) {
          const { aswoSparePart } = await getAswoSparePart(aswoSkuModelReference.reference);
          if (aswoSparePart) {
            sku.aswoPayload = {
              verboseDelay: aswoSparePart.Lieferzeit,
              delay: aswoSparePart.maxliefertage,
              price: parseFloat(aswoSparePart.Stueckpreis * 100),
            };
          }
        }
      })
    );
  };

  const selectDefaultSku = (skus) =>
    skus.find((sku) => sku.type === SKU_TYPE_NEW && sku.stock_available >= sparePartData.quantity) || skus[0];

  const handleChangeSku = async (sku) => {
    const sellingPrice = await getSkuSellingPrice(sku.id);
    updateSparePartData({
      sku,
      designation: sku.customer_designation || sku.designation,
      buyingPriceInCents: sku.aswoPayload && sku.aswoPayload.price,
      sellingPrice: sellingPrice ? sellingPrice / 100 : null,
    });
  };

  const handleValidateSparePart = () => {
    const sparePartDataToAdd = createSparePartData();
    addSparePart(Array(sparePartData.quantity).fill(sparePartDataToAdd));
    handleCloseSparePart();
    updateModalState({ isSparePartModalOpen: false });
  };

  const createSparePartData = () => {
    const { sku, designation, buyingPriceInCents } = sparePartData;
    const amountWithtaxInCents = calculateAmountWithTax();
    const amountPretaxInCents = getPricePretax(amountWithtaxInCents);
    const supplierName = getSupplierName();
    const selectedReference = getSelectedReference();

    return {
      home_repair_product: productId,
      admin_comments: null,
      amount_pretax: amountPretaxInCents,
      amount_withtax: amountWithtaxInCents,
      base_price: calculateBasePrice(),
      customer_file: null,
      demand_item: {
        price_pretax: parseInt(buyingPriceInCents) || 0,
        aswo_announced_delay: sku && sku.aswoPayload ? sku.aswoPayload.delay : undefined,
        aswo_verbose_delay: sku && sku.aswoPayload ? sku.aswoPayload.verboseDelay : undefined,
        is_in_murfy_stock: sku ? sku.stock_available > 0 : false,
        is_second_hand: sku ? sku.type === SKU_TYPE_SECOND_HAND : false,
        sku: sku ? sku.id : null,
        preferred_reference: sparePartData.reference,
      },
      element_type: "spare_part",
      name: designation,
      quote_billing: null,
      sku_model: {
        designation: sku ? sku.designation : designation,
        supplier: supplierName,
        supplier_reference: selectedReference,
        sku_reference: `${supplierName}_${selectedReference}`,
      },
      spare_part: {
        piece_name: designation,
        aswo_ref: selectedReference,
        exploded_view_ref: isStockInstallation ? "from_stock" : "",
        price_pretax: parseInt(buyingPriceInCents) || 0,
        state: "unaccepted_quote",
        quantity: 1,
      },
    };
  };

  const calculateAmountWithTax = () => {
    const { isSparePartPriceEditable, forcedSparePartPriceWithTax, sellingPrice, buyingPriceInCents } = sparePartData;
    if (isSparePartPriceEditable) {
      return forcedSparePartPriceWithTax * 100;
    }
    const basePriceFromCost = getSparePartBasePriceFromSparePartCost(buyingPriceInCents / 100);
    const sparePartPriceFromCost =
      getSparePartPriceWithTax(basePriceFromCost, sparePartData.quantity, newSparePartElementsCount) * 100;
    return sellingPrice
      ? getSparePartPriceWithTax(sellingPrice, sparePartData.quantity, newSparePartElementsCount) * 100
      : sparePartPriceFromCost;
  };

  const calculateBasePrice = () => {
    const { isSparePartPriceEditable, forcedSparePartPriceWithTax, sellingPrice, buyingPriceInCents } = sparePartData;
    if (isSparePartPriceEditable) {
      return forcedSparePartPriceWithTax * 100;
    }
    if (sellingPrice) {
      return sellingPrice * 100;
    }
    const basePriceFromCost = getSparePartBasePriceFromSparePartCost(buyingPriceInCents / 100);
    return parseInt(basePriceFromCost * 100);
  };

  const getSupplierName = () => {
    const { sku, supplier } = sparePartData;
    if (!sku) {
      return supplier.name;
    }
    const matchingReference = sku.references.find((ref) => ref.supplier_name === supplier.name);
    return matchingReference ? matchingReference.supplier_name : supplier.name;
  };

  const getSelectedReference = () => {
    const { sku, supplier, reference } = sparePartData;
    if (!sku) {
      return reference;
    }
    const matchingReference = sku.references.find((ref) => ref.supplier_name === supplier.name);
    return matchingReference ? matchingReference.reference : reference;
  };

  const onClickChangeEditabilityOfSparePartPrice = () => {
    updateSparePartData({
      isSparePartPriceEditable: true,
      forcedSparePartPriceWithTax:
        getSparePartPriceWithTax(sparePartData.sellingPrice, sparePartData.quantity, newSparePartElementsCount) *
        sparePartData.quantity,
    });
  };

  return (
    <div className={classes.addSparePartWrapper}>
      <SimpleForm submitOnEnter={false} redirect={false} className={classes.root} noValidate>
        {modalState.isAswoModalOpen && (
          <AddSparePartASWORefSelection
            isOpen={modalState.isAswoModalOpen}
            handleCloseSparePart={handleCloseSparePart}
            handleReferenceChange={(reference) => updateSparePartData({ reference })}
            goToAswoModalNoReference={() => {
              updateModalState({ isSparePartModalOpen: true });
              updateSparePartData({ isNoRefPiece: true });
            }}
            onSearchClick={findSkus}
            setSupplier={(supplier) => updateSparePartData({ supplier })}
            supplier={sparePartData.supplier}
            spareAswoRef={sparePartData.reference}
          />
        )}
        {modalState.isSparePartModalOpen &&
          (sparePartData.isNoRefPiece ? (
            <AddSparePartNoRef
              handleCloseSparePart={handleCloseSparePart}
              sparePartsAlreadyInCart={newSparePartElementsCount}
              quantity={sparePartData.quantity}
              sellingPrice={sparePartData.sellingPrice}
              isSparePartModalOpen={modalState.isSparePartModalOpen}
              handleValidateSparePart={handleValidateSparePart}
              onClickChangeEditabilityOfSparePartPrice={onClickChangeEditabilityOfSparePartPrice}
              setSupplier={(supplier) => updateSparePartData({ supplier })}
              setReference={(reference) => updateSparePartData({ reference })}
              setDesignation={(designation) => updateSparePartData({ designation })}
              setBuyingPriceInCents={(buyingPriceInCents) => updateSparePartData({ buyingPriceInCents })}
              setQuantity={(quantity) => updateSparePartData({ quantity })}
              setForcedSparePartPriceWithTax={(forcedSparePartPriceWithTax) =>
                updateSparePartData({ forcedSparePartPriceWithTax })
              }
              forcedSparePartPriceWithTax={sparePartData.forcedSparePartPriceWithTax}
              isSparePartPriceEditable={sparePartData.isSparePartPriceEditable}
              goToAswoModal={() => {
                updateModalState({ isAswoModalOpen: true, isSparePartModalOpen: false });
              }}
              spareAswoRef={sparePartData.reference}
              spareName={sparePartData.designation}
              supplier={sparePartData.supplier}
              buyingPriceInCents={sparePartData.buyingPriceInCents}
            />
          ) : (
            <AddSparePartRefFound
              skus={sparePartData.skus}
              selectedSku={sparePartData.sku}
              setSku={handleChangeSku}
              handleCloseSparePart={handleCloseSparePart}
              sparePartsAlreadyInCart={newSparePartElementsCount}
              quantity={sparePartData.quantity}
              sellingPrice={sparePartData.sellingPrice}
              isSparePartModalOpen={modalState.isSparePartModalOpen}
              handleValidateSparePart={handleValidateSparePart}
              onClickChangeEditabilityOfSparePartPrice={onClickChangeEditabilityOfSparePartPrice}
              setDesignation={(designation) => updateSparePartData({ designation })}
              setForcedSparePartPriceWithTax={(forcedSparePartPriceWithTax) =>
                updateSparePartData({ forcedSparePartPriceWithTax })
              }
              setBuyingPriceInCents={(buyingPriceInCents) => updateSparePartData({ buyingPriceInCents })}
              setQuantity={(quantity) => updateSparePartData({ quantity })}
              forcedSparePartPriceWithTax={sparePartData.forcedSparePartPriceWithTax}
              isSparePartPriceEditable={sparePartData.isSparePartPriceEditable}
              spareAswoRef={sparePartData.reference}
              spareName={sparePartData.designation}
              supplier={sparePartData.supplier}
              buyingPriceInCents={sparePartData.buyingPriceInCents}
            />
          ))}
      </SimpleForm>
    </div>
  );
};

export default withStyles(AddSparePartStyles)(AddSparePart);
