import React from "react";

import { Box } from "@material-ui/core";

import moment from "moment";
import { Diagnostic } from "types/diagnostic";

import { Body, BodyBold } from "components/atoms/Typography";

import { useProduct } from "utils/hooks/useProduct";

interface ProductDetailProps {
  diagnostic: Diagnostic;
}

const ProductTitle = ({ diagnostic }: ProductDetailProps) => {
  const { data, isLoading, isError } = useProduct(diagnostic.appliance_repair.product_id);

  if (isLoading && !data) {
    return <div>Récupération du produit</div>;
  }

  if (isError || !data) {
    return <div>Echec de la récupération du produit</div>;
  }

  const productString = `${data.product_type} ${data.brand || ""} ${data.model_reference || ""} `;
  const dateInfoString = `soumis le ${moment(diagnostic.finalized_at).format("DD/MM/YYYY à HH:mm")}`;

  return (
    <Box display="flex">
      <BodyBold>{productString}</BodyBold> &nbsp; <Body>{dateInfoString}</Body>
    </Box>
  );
};

export default ProductTitle;
