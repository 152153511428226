import React from "react";

import { Box, styled } from "@material-ui/core";

import {
  Attachment,
  Blocage,
  Default,
  Diagnostic as DiagnosticType,
  Resolution,
  Symptom,
  UnfulfilledReplacement,
} from "types/diagnostic";

import { BodyBold } from "components/atoms/Typography";
import CollapsibleBlock from "components/molecules/CollapsibleBlock";

import { useDiagnosticSparePartFittings } from "utils/hooks/useDiagnosticSparePartFittings";

import ProductTitle from "../ProductTitle/ProductTitle";
import { DiagnosticPictures } from "./components";
import DiagnosticItems from "./components/DiagnosticItems";

interface DiagnosticProps {
  diagnostic: DiagnosticType;
}

const StyledBox = styled(Box)(({ theme }: any) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export const Diagnostic = ({ diagnostic }: DiagnosticProps) => {
  const getSpecificDiagItem = (type: string, context?: string) =>
    diagnostic.diagnostic_items.filter((item: any) => item.type === type && (!context || item.context === context));

  const spare_part_fittings = useDiagnosticSparePartFittings(diagnostic);

  return (
    <CollapsibleBlock indent={2} size="sm" openByDefault={false} title={<ProductTitle diagnostic={diagnostic} />}>
      <StyledBox>
        <BodyBold>Symptôme(s)</BodyBold>
        <DiagnosticItems items={getSpecificDiagItem("symptom") as Symptom[]} />
      </StyledBox>
      <StyledBox>
        <BodyBold>Défaut(s)</BodyBold>
        <DiagnosticItems items={getSpecificDiagItem("default") as Default[]} />
      </StyledBox>
      <StyledBox>
        <BodyBold>Résolution(s) sans pièce</BodyBold>
        <DiagnosticItems items={getSpecificDiagItem("resolution", undefined) as Resolution[]} />
      </StyledBox>
      <StyledBox>
        <BodyBold>Pièce(s) nécessaire(s)</BodyBold>
        <DiagnosticItems
          items={diagnostic.diagnostic_items.filter(
            (item): item is UnfulfilledReplacement => item.type === "replacement" && !item.spare_part_fitting
          )}
        />
      </StyledBox>
      <StyledBox>
        <BodyBold>Pièce(s) Posée(s)</BodyBold>
        <DiagnosticItems items={spare_part_fittings} />
      </StyledBox>
      <StyledBox>
        <BodyBold>Etat de l'appareil à la fin de l'intervention</BodyBold>
        <Box> {diagnostic.appliance_is_repaired ? "Fonctionnel" : "En Panne"}</Box>
      </StyledBox>
      <StyledBox>
        <BodyBold>Problème(s)</BodyBold>
        <DiagnosticItems items={getSpecificDiagItem("blocage", undefined) as Blocage[]} />
      </StyledBox>
      {diagnostic.note_for_reopening && diagnostic.note_for_reopening.content !== "" && (
        <StyledBox>
          <BodyBold>Note en cas de réouverture</BodyBold>
          <Box>{diagnostic.note_for_reopening.content}</Box>
        </StyledBox>
      )}
      {diagnostic.note_for_next_intervention && diagnostic.note_for_next_intervention.content !== "" && (
        <StyledBox>
          <BodyBold>Note pour la prochaine intervention</BodyBold>
          <Box>{diagnostic.note_for_next_intervention.content}</Box>
        </StyledBox>
      )}
      <StyledBox>
        <BodyBold>Photos</BodyBold>
        <DiagnosticPictures pictures={getSpecificDiagItem("attachment", undefined) as Attachment[]} />
      </StyledBox>
    </CollapsibleBlock>
  );
};

export default Diagnostic;
