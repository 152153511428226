import React from "react";

import { Box, styled } from "@material-ui/core";

import moment from "moment";
import { CustomerFileDetailed } from "types/customer-files";
import { Employee } from "types/employees";
import { Product } from "types/products";
import { Visit, VisitOutcome } from "types/visits";

import { Body, BodyBold } from "components/atoms/Typography";
import List from "components/molecules/List/List";

import LeadOpinion from "pages/Tickets/components/Details/LeadOpinion";

import { useRepairmen } from "utils/api";
import { getEmployeeFullName } from "utils/repairmenSortedList";

import IncentiveRequalification from "../IncentiveRequalification/IncentiveRequalification";
import { ProductDiagnosticHistory } from "../ProductDiagnosticHistory";
import VisitOutcomeWithProduct from "../VisitOutcomeWithProduct";
import VisitPaymentStatus from "../VisitPaymentStatus";

const TitleBlockPart = styled(Box)({
  flex: "25%",
  textAlign: "left",
});
const TitleOutcomeBlockPart = styled(Box)({
  flex: "50%",
  textAlign: "left",
});

const INTERVENTION_STATUSES = ["done", "intervention"];

const getEmployeeName = (employees: any[], assigned_repairman?: string): string => {
  const tech =
    employees &&
    assigned_repairman &&
    employees.find((repairman: Employee) => assigned_repairman && repairman.id === parseInt(assigned_repairman));
  if (!tech) {
    return "Réparateur inconnu";
  }
  return getEmployeeFullName(tech);
};

const DiagnosicsPerVisit = ({ customerFile }: { customerFile: CustomerFileDetailed }) => {
  const repairmen = useRepairmen();
  const interventions = customerFile.visits
    .filter((visit) => INTERVENTION_STATUSES.includes(visit.visit_status))
    .sort((a, b) => new Date(b.timeslot.beginning).getTime() - new Date(a.timeslot.beginning).getTime());

  return interventions.map((visit: Visit, index: number) => (
    <List
      defaultOpen={index === 0}
      titleBlock={
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <TitleBlockPart>
            <BodyBold>{moment(visit.timeslot.beginning).format("YYYY-MM-DD")}</BodyBold>
          </TitleBlockPart>
          <TitleBlockPart>
            <Body>{getEmployeeName(repairmen, visit.assigned_repairman)}</Body>
          </TitleBlockPart>
          <TitleOutcomeBlockPart>
            {visit.outcomes.map((outcome: VisitOutcome) => {
              return <VisitOutcomeWithProduct visitOutcome={outcome} />;
            })}
          </TitleOutcomeBlockPart>
        </Box>
      }
      subtitle={<VisitPaymentStatus visitId={visit.id} balance={customerFile.balance} />}
      items={customerFile.products.map((product: Product) => {
        return (
          <>
            <Box>
              <ProductDiagnosticHistory product={product} visitId={visit.id} />
            </Box>
            <Box mt={2}>
              <IncentiveRequalification product={product} visitId={visit.id} />
            </Box>
            <Box mt={2}>
              <LeadOpinion
                productId={product.id}
                customerFile={customerFile}
                getCustomerFileDetail={() => {}}
                shouldRefreshInterface={() => {}}
              />
            </Box>
          </>
        );
      })}
    />
  ));
};

export default DiagnosicsPerVisit;
