import React, { useCallback, useEffect, useState } from "react";

import { useNotify } from "react-admin";

import { Box, CircularProgress, Typography } from "@material-ui/core";
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, List } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { getQuotes, postPayment, postQuote, updateQuote } from "utils/api";

import NewQuoteForm from "./components/NewQuoteForm";
import QuoteDetails from "./components/QuoteDetails";
import QuoteHeader from "./components/QuoteHeader.component";
import { getLastQuote, getQuoteAmountWithTax } from "./services/QuoteHelper";
import { RepairAssistantService } from "./services/RepairAssistantService";
import { quoteStyles } from "./styles/quoteStyles";

const StyledExpansionPanelDetails = withStyles({
  root: {
    display: "flex",
    padding: "8px 32px 15px",
    textAlign: "right",
  },
})(ExpansionPanelDetails);

const StyledExpansionPanelSummary = withStyles({
  root: {
    display: "flex",
    minHeight: "86px",
    padding: "0 40px 0 40px",
  },
})(ExpansionPanelSummary);

const StyledExpansionPanel = withStyles({
  root: {
    "&:last-child": {
      borderRadius: "8px",
      boxShadow: "none",
    },
  },
})(ExpansionPanel);

export const useQuotes = (customerFileId, openDefaultEditionSession) => {
  const notify = useNotify();
  const [quotes, setQuotes] = useState([]);
  const [lastQuote, setLastQuote] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [shouldRefetchQuotes, setShouldRefetchQuotes] = useState(false);

  const fetchQuotes = useCallback(
    async (isNewQuoteFormOpen) => {
      if (!isNewQuoteFormOpen || shouldRefetchQuotes) {
        setIsLoading(true);
        try {
          const fetchedQuotes = await getQuotes(customerFileId);
          const quotesData = fetchedQuotes.data;
          await openDefaultEditionSession(quotesData);
          setQuotes(quotesData);
          setLastQuote(quotesData[quotesData.length - 1]);
          setShouldRefetchQuotes(false);
        } catch (error) {
          notify(`Error fetching quotes: ${error.message}`, "error");
        } finally {
          setIsLoading(false);
        }
      }
    },
    [customerFileId, shouldRefetchQuotes, notify, openDefaultEditionSession]
  );

  return {
    quotes,
    lastQuote,
    isLoading,
    fetchQuotes,
    setShouldRefetchQuotes,
    setLastQuote,
    setIsLoading,
  };
};

const CustomerFileQuoteBilling = ({
  customerFile,
  customerFilesStates,
  employees,
  visits,
  saveCurrentEditionSession,
  resetCurrentEditionSession,
  currentEditionSession,
  classes,
  quoteBillingList,
}) => {
  const notify = useNotify();
  const [isNewQuoteFormOpen, setIsNewQuoteFormOpen] = useState(false);
  const [amountLastNonRefusedQuote, setAmountLastNonRefusedQuote] = useState(null);
  const [sparePartsOrders, setSparePartsOrders] = useState([]);
  const [hermeticUnitsOrders, setHermeticUnitsOrders] = useState([]);

  const fetchSparePartsAndHermeticUnits = async (lastQuote, visits, customerFile) => {
    const repairAssistantService = new RepairAssistantService();
    const sparePartOrdersFromAssistant = await repairAssistantService.getSparePartOrder(
      customerFile,
      lastQuote.created_at
    );

    // Get all tickets that were created after the last quote
    const newestVisits = visits.filter((visit) => visit.timeslot && visit.timeslot.beginning > lastQuote.created_at);
    const tickets = newestVisits.reduce((tickets, visit) => tickets.concat(visit.tickets), []);
    const products = tickets
      .filter((ticket) => !!ticket.ticket_payload)
      .reduce((products, ticket) => products.concat(ticket.ticket_payload.payload.products), []);

    const productData = products.map((product) => {
      const productId = parseInt(product.product_id);

      const sparePartsOrders = (product.spare_parts_orders || []).map((order) => ({
        ...order,
        productId,
      }));

      const hermeticUnitsOrders = (product.hermetic_unit_order || []).map((order) => ({
        ...order,
        productId,
      }));

      return {
        productId,
        sparePartsOrders,
        hermeticUnitsOrders,
      };
    });

    // Combine all spare parts orders and hermetic units orders
    const sparePartsOrders = productData.flatMap((product) => product.sparePartsOrders);
    const hermeticUnitsOrders = productData.flatMap((product) => product.hermeticUnitsOrders);

    return {
      sparePartsOrders: sparePartsOrders.concat(sparePartOrdersFromAssistant),
      hermeticUnitsOrders,
    };
  };

  const openDefaultEditionSession = useCallback(
    async (quotes) => {
      const customerFileState = Object.values(customerFilesStates).find((state) => state.id === customerFile.state);
      const lastQuote = getLastQuote(quotes);
      const lastNonRefusedQuote = getLastQuote(quotes.filter((quote) => !quote.quote_refused));

      const { sparePartsOrders, hermeticUnitsOrders } = await fetchSparePartsAndHermeticUnits(
        lastQuote,
        visits,
        customerFile
      );

      const shouldCreateDraft =
        customerFileState &&
        (customerFileState.name === "CR à traiter" || customerFileState.name === "CR à vérifier") &&
        sparePartsOrders.length + hermeticUnitsOrders.length > 0;
      const shouldShowPendingDraft = currentEditionSession && currentEditionSession.isEditionSessionPending;

      setSparePartsOrders(sparePartsOrders);
      setHermeticUnitsOrders(hermeticUnitsOrders);
      setIsNewQuoteFormOpen(shouldCreateDraft || shouldShowPendingDraft);
      setAmountLastNonRefusedQuote(lastNonRefusedQuote && getQuoteAmountWithTax(lastNonRefusedQuote.quote_elements));
    },
    [customerFile, customerFilesStates, currentEditionSession, visits]
  );
  const { quotes, lastQuote, isLoading, fetchQuotes, setShouldRefetchQuotes, setLastQuote, setIsLoading } = useQuotes(
    customerFile.id,
    openDefaultEditionSession
  );

  useEffect(() => {
    fetchQuotes(isNewQuoteFormOpen);
  }, [fetchQuotes, isNewQuoteFormOpen]);

  const handleDisplayNewQuoteForm = (quote) => {
    setIsNewQuoteFormOpen(!isNewQuoteFormOpen);
    setLastQuote(quote);
  };

  const handleQuoteCreation = async (quote, newElements, emailTemplate, announced_delay) => {
    setIsLoading(true);
    try {
      const mergedElements = newElements ? [...quote.quote_elements, ...newElements] : quote.quote_elements;
      await postQuote({
        version: lastQuote.version + 1,
        quote_elements: mergedElements,
        customer_file: customerFile.id,
        email_template: emailTemplate,
        announced_delay: announced_delay,
      });

      setIsNewQuoteFormOpen(false);
      notify("Le devis a été créé", "success");
    } catch (error) {
      notify(`Une erreur est survenue: ${error.message}`, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handlePaymentCreation = async (
    paymentNature,
    amount,
    paymentType,
    selectedTech,
    selectedDate,
    paymentPartner,
    partnerReference,
    quoteId
  ) => {
    try {
      await postPayment({
        payment_nature: paymentNature,
        amount: amount,
        payment_method: paymentType,
        employee: selectedTech,
        payment_date: selectedDate,
        payment_solution: paymentPartner,
        solution_reference: partnerReference,
        quote_id: quoteId,
        customer_file: customerFile.id,
      });
      notify("Paiement ajouté", "success");
      setShouldRefetchQuotes(true);
    } catch (error) {
      notify(`Une erreur est survenue, le paiement n'a pas été créé: ${error.message}`, "error");
    }
  };

  const handleValidateQuoteUpdate = async (quote, quoteStatus, sendConfirmationEmail = false) => {
    try {
      await updateQuote(quote.id, { ...quoteStatus, send_confirmation_email: sendConfirmationEmail });
      notify("Le devis a bien été modifié", "success");
      setShouldRefetchQuotes(true);
    } catch (error) {
      notify(`Une erreur est survenue, le devis n'a pas été modifié: ${error.message}`, "error");
    }
  };

  return (
    <>
      <List>
        {isLoading ? (
          <Box textAlign="center">
            <Typography variant="body1" color="primary">
              Chargement des devis en cours
            </Typography>
            <CircularProgress color="secondary" />
          </Box>
        ) : (
          quotes.map((quote, index) => (
            <div className={classes.table} key={index}>
              <StyledExpansionPanel defaultExpanded={quote === lastQuote && !isNewQuoteFormOpen}>
                <StyledExpansionPanelSummary>
                  <QuoteHeader record={quote} />
                </StyledExpansionPanelSummary>
                <StyledExpansionPanelDetails>
                  <QuoteDetails
                    record={quote}
                    handlePaymentCreation={handlePaymentCreation}
                    handleDisplayNewQuoteForm={handleDisplayNewQuoteForm}
                    quoteBillingList={quoteBillingList}
                    handleUpdateQuote={handleValidateQuoteUpdate}
                    isNewQuoteFormOpen={isNewQuoteFormOpen}
                    employees={employees}
                    visits={visits}
                    customerFile={customerFile}
                    getQuotes={getQuotes}
                    amountLastNonRefusedQuote={amountLastNonRefusedQuote}
                    isLast={!(quote === lastQuote && !isNewQuoteFormOpen)}
                  />
                </StyledExpansionPanelDetails>
              </StyledExpansionPanel>
            </div>
          ))
        )}
      </List>
      {isNewQuoteFormOpen && lastQuote && (
        <div id="quote-creation-form">
          <NewQuoteForm
            quote={lastQuote}
            customerFile={customerFile}
            handleQuoteCreation={handleQuoteCreation}
            classes={classes}
            handleDisplayNewQuoteForm={handleDisplayNewQuoteForm}
            employees={employees}
            visits={visits}
            saveCurrentEditionSession={saveCurrentEditionSession}
            resetCurrentEditionSession={resetCurrentEditionSession}
            currentEditionSession={currentEditionSession}
            sparePartsOrders={sparePartsOrders}
            hermeticUnitsOrders={hermeticUnitsOrders}
          />
        </div>
      )}
    </>
  );
};

export default withStyles(quoteStyles)(CustomerFileQuoteBilling);
