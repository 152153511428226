import React from "react";

import { Box } from "@material-ui/core";

import { Body } from "components/atoms/Typography";

import { usePaymentIssue } from "utils/hooks/usePaymentIssue";

type VisitPaymentStatusProps = {
  visitId: number;
  balance: number;
};

const VisitPaymentStatus: React.FC<VisitPaymentStatusProps> = ({ visitId, balance }) => {
  const { issue, isLoading, isError } = usePaymentIssue(visitId);

  if ((!isLoading && !isError && issue) || balance !== 0) {
    return (
      <Box color="secondary.red" display="block">
        <Body>Attention, état du paiement du dossier à vérifier.</Body>

        {issue ? (
          <Body>Information additionnelle indiquée par le technicien: {issue.reason}</Body>
        ) : (
          <Body>La balance du dossier n'est pas nulle</Body>
        )}
      </Box>
    );
  }
  return <></>;
};

export default VisitPaymentStatus;
